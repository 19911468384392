import { post, put } from "@/network/axiosClient"
import { useCallback } from "react"
import { getAccessToken } from "../utils/authentication"

export const useGenSignature = () => {
  const genSignature = useCallback(
    async (id: string, payload: any, cbs?: any, cbe?: any) => {
      await post(`/transactions/gen-signature/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }).then((results) => {
        if (results?.status === 201) {
          if (cbs) cbs(results?.data?.data)
        } else {
          if (cbe) cbe(results)
        }
      }).catch(error => {
        if (cbe) cbe(error)
      })
    },
    []
  )

  return genSignature
}

export const useFillOrder = () => {
  const fillOrder = useCallback(
    async (id: string, payload: any, cbs?: any, cbe?: any) => {
      await put(`/transactions/fill-order/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }).then((results) => {
        if (results?.status === 200) {
          if (cbs) cbs(results?.data?.data)
        } else {
          if (cbe) cbe(results)
        }
      })
    },
    []
  )

  return fillOrder
}

export const useCancelOrder = () => {
  const cancelOrder = useCallback(async (id: string, cbs?: any, cbe?: any) => {
    await put(
      `/transactions/cancel-order/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    ).then((results) => {
      if (results?.status === 200) {
        if (cbs) cbs(results?.data?.data)
      } else {
        if (cbe) cbe(results)
      }
    })
  }, [])

  return cancelOrder
}
