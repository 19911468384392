import { StatusCodeResponse } from "./constants";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";
import { store } from "../app/rootStore";
import { changeMessage } from "../components/common/toastMessage/store/slice";
import { routes } from "../router/constants";

const pendingRequest = new Map();
// const CancelToken = axios.CancelToken;

const baseURL = process.env.REACT_APP_API_URL;

const defaultHeaders = {
  "Content-Type": "application/json",
};

const axiosClient = axios.create({
  baseURL,
  responseType: "json",
  timeout: 30000,
});

const defaultConfig = async (): Promise<AxiosRequestConfig> => {
  const defaultConfig: AxiosRequestConfig = {
    headers: {
      ...defaultHeaders,
    },
  };

  return defaultConfig;
};

const mergeConfig = async (
  config?: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const _defaultConfig = await defaultConfig();
  let headers = _defaultConfig.headers;

  if (config && config.headers) {
    headers = { ...headers, ...config.headers };
  }

  return { ..._defaultConfig, ...config, headers };
};

export async function get(
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> {
  const _config = await mergeConfig(config);

  return axiosClient
    .get(url, _config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response || error;
    });
}

export async function post(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> {
  const _config = await mergeConfig(config);

  return axiosClient
    .post(url, data, _config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response || error;
    });
}

export async function put(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> {
  const _config = await mergeConfig(config);

  return axiosClient
    .put(url, data, _config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response || error;
    });
}

export async function patch(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> {
  const _config = await mergeConfig(config);

  return axiosClient
    .patch(url, data, _config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response || error;
    });
}

export async function deleteRequest(
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> {
  const _config = await mergeConfig({ ...config });

  return axiosClient
    .delete(url, _config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response || error;
    });
}

export async function uploadFile(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> {
  const _config = await mergeConfig({ ...config });
  return axiosClient
    .post(url, data, _config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response || error;
    });
}

//  Get request key
function getReqKey(config: AxiosRequestConfig) {
  //  Request mode 、 Request address 、 The string generated by the request parameter is used as the basis for whether to repeat the request
  const { method, url, params, data } = config; //  Deconstruct these parameters
  // GET ---> params POST ---> data
  const requestKey = [
    method,
    url,
    qs.stringify(params),
    qs.stringify(data),
  ].join("&");
  return requestKey;
}

//  Cancel duplicate request
function removeReqKey(key: string) {
  const cancelToken = pendingRequest.get(key);
  cancelToken(key); //  Cancel a previously sent request
  pendingRequest.delete(key); //  Delete from request object requestKey
}

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    //  What to do before sending the request

    //  Get request key
    let requestKey = getReqKey(config);

    //  Determine whether it is a duplicate request
    if (pendingRequest.has(requestKey)) {
      //  It's a repeat request
      // removeReqKey(requestKey); //  Cancel
    } else {
      //  Set up cancelToken
      // config.cancelToken = new CancelToken(function executor(cancel) {
      //   pendingRequest.set(requestKey, cancel); //  Set up
      // });
    }

    return config;
  },

  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },

  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === StatusCodeResponse.UNAUTHORIZED) {
      // store.dispatch(
      //   changeMessage({
      //     message: "Token is expired. Pls Login again!",
      //     statusCode: 400,
      //   })
      // );
      localStorage.clear();
    }
    let requestKey = getReqKey(error.config);
    // removeReqKey(requestKey);
    return Promise.reject(error);
  }
);

export default axiosClient;
