import { ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import { notification } from "antd";
import { message } from "antd";
import { get, post } from "network/axiosClient";
import { LoginResponse } from "components/connectWallet/store/type";
import { useDispatch } from "react-redux";
import { setAuthentication } from "components/connectWallet/store/slice";
import { uuid } from "uuidv4";
import moment from "moment";
import { getAuthorizationHeader } from "@/utils/authentication";
import { useAccount } from "wagmi";
import { useEthersSigner } from "@/hooks/useEthersSigner";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const useConnectWallet = () => {
  const dispatch = useDispatch();

  const [walletAddress, setWalletAddress] = useState<String | null>(null);
  const [isConnectMetamask, setIsConnectMetamask] = useState<boolean>(false);
  const [openSignWallet, setOpenSignWallet] = useState(false);

  const { address, isConnected, connector } = useAccount();
  const { openConnectModal } = useConnectModal();
  const signer = useEthersSigner();

  const currentAccount = useRef<string>("");

  const [_, contextHolder] = notification.useNotification();

  useEffect(() => {
    const accessTokenStorage = localStorage.getItem("accessToken");
    const walletAddressStorage = localStorage.getItem("walletAddress");

    if (accessTokenStorage && walletAddressStorage) {
      setWalletAddress(walletAddressStorage);
    }
  }, []);

  useEffect(() => {
    const accessTokenStorage = localStorage.getItem("accessToken");
    const walletAddressStorage = localStorage.getItem("walletAddress");
    if (
      address &&
      isConnected &&
      (!accessTokenStorage || !walletAddressStorage)
    ) {
      setOpenSignWallet(true);
    }
  }, [address, isConnected]);

  const callLogin = async (
    walletAddress: string,
    signature: string,
    generateUUID: string
  ) => {
    try {
      let params = {
        walletAddress,
        deadline: moment().unix() + 5 * 60 * 1000,
        sig: signature,
        uuid: generateUUID,
      };

      const res: LoginResponse = await post("/auth/sign-in", params);
      if (res?.data?.success) {
        localStorage.setItem(
          "accessToken",
          res?.data?.data?.access_token || ""
        );
        localStorage.setItem(
          "refreshToken",
          res?.data?.data?.refresh_token || ""
        );
        localStorage.setItem("walletAddress", walletAddress);

        setWalletAddress(walletAddress);
        dispatch(
          setAuthentication({
            accessToken: res?.data?.data?.access_token,
            refreshToken: res?.data?.data?.refresh_token,
            walletAddress,
          })
        );

        await get("/moralis/collection-nfts", {
          headers: getAuthorizationHeader(),
        });

        message.success("Login Success", 3);
      }
    } catch (e) {
      message.error(e?.message);
    }
  };

  const signAndAccept = async (callback?: () => void) => {
    if (!isConnected || !address || !signer) {
      openConnectModal();
      message.success("Login Failed", 3);
      callback?.();
      return;
    } else {
      try {
        const generateUUID = uuid();
        const message = `Welcome to Monbase NFT! Click to sign in and accept the Monbase NFT Terms of Service: https://nft.monbase.com. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 24 hours. Wallet address: ${address?.toLowerCase()}. Nonce: ${generateUUID}`;
        const signature = await signer?.signMessage(message);
        const verify = ethers.utils.verifyMessage(message, signature ?? "");
        localStorage.setItem("provider", connector?.name);

        if (verify) {
          await callLogin(address ?? "", signature ?? "", generateUUID);
        }
        callback?.();
      } catch (e) {
        message.error(e?.message, 3);
        callback?.();
      }
    }
  };

  function checkSignConnectWallet() {
    const walletAddressStorage = localStorage.getItem("walletAddress");
    const accessTokenStorage = localStorage.getItem("accessToken");

    if (walletAddressStorage && !accessTokenStorage) {
      setWalletAddress(walletAddressStorage);
      return true;
    }
    return false;
  }

  function checkAccessToken() {
    const accessTokenStorage = localStorage.getItem("accessToken");

    if (accessTokenStorage) {
      return true;
    }
    return false;
  }

  function checkMetamaskProvider() {
    const providerStorage = localStorage.getItem("provider");
    if (providerStorage === "metamask") {
      return true;
    }
    return false;
  }

  return {
    walletAddress,
    setWalletAddress,
    currentAccount,
    contextHolder,
    isConnectMetamask,
    checkSignConnectWallet,
    openSignWallet,
    setOpenSignWallet,
    checkMetamaskProvider,
    account: address,
    signAndAccept,
    checkAccessToken,
    setIsConnectMetamask,
  };
};

export default useConnectWallet;
